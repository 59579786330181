import { env } from "~/env"

export const SHOPIFY_SHOP_URL = "bonsoirs.myshopify.com"
export const CHECKOUT_URL = env.NEXT_PUBLIC_CHECKOUT_DOMAIN

export const ALLOWED_NEWSLETTER_TAGS = ["campaign", "from", "currency", "country", "language", "locale"] as const
export const HIDDEN_COLLECTION_BREADCRUMB_HANDLES = ["synchro"]
export const VALID_PRODUCTS_TAGS = ["newcata", "product-simple"] as const

export const SEARCH_FILTER_METAFIELD = {
  productMetafield: {
    key: "is_searchable",
    namespace: "product",
    value: "true",
  },
}

export * from "./custom-attributes"
export * from "./metafields"
